import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  OnInit,
} from "@angular/core";

import { Web3Provider } from "@ethersproject/providers";
import { chainIdList, HelpInfo, walletInfo } from "app/auth/helpers";
import { WalletService } from "app/auth/service/wallet.service";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { BreakpointObserver } from "@angular/cdk/layout";
import { TranslateService } from "@ngx-translate/core";
import { CoreTranslationService } from "@core/services/translation.service";
import { locale as russian } from "./i18n/ru";
import { locale as english } from "./i18n/en";
import { locale as chine } from "./i18n/zh";
import { locale as hindi } from "./i18n/hi";
import { Clipboard } from "@angular/cdk/clipboard";
import { Router } from "@angular/router";
import { MyWeb3ModalService } from "app/auth/service/web3Modal.service";
import { ENERGY_ADDRESSES, EQN_ADDRESSES, QEN_ADDRESSES, QRE_ADDRESSES } from 'localModules/metaforcesdk/constants';
declare let window: any;

@Component({
  selector: "app-navbar-wallets",
  templateUrl: "./navbar-wallets.component.html",
  styleUrls: ["./navbar-wallets.component.scss"],
})
export class NavbarWalletsComponent implements OnInit, OnDestroy {
  @Input() isSpare: boolean = false;
  @Input() reservedAddress = "";

  paymentBalance = [
    {
      token: "",
      value: 0,
      name: "HC",
      imgName: "forcecoin",
      isVisible:true,
    },
    {
      token: "",
      value: 0,
      name: "DAI",
      imgName: "dai",
      isVisible:true,
    },
    
    {
      token: '',
      value: 0,
      name: 'RES',
      imgName: 'energy',
      isVisible:false,
    },
    {
      token: '',
      value: 0,
      name: 'QRE',
      imgName: 'QRE',
      isVisible:false,
    },
    {
      token: '',
      value: 0,
      name: 'NTC',
      imgName: 'NTC',
      isVisible:false,
    },
    {
      token: '',
      value: 0,
      name: 'EQN',
      imgName: 'EQN',
      isVisible:false,
    },
  ];
  walletBalance = [
    {
      token: "",
      value: 0,
      name: "HC",
      imgName: "forcecoin",
      isVisible:true,
    },
    {
      token: "",
      value: 0,
      name: "DAI",
      imgName: "dai",
      isVisible:true,
    },
    {
      token: '',
      value: 0,
      name: 'RES',
      imgName: 'energy',
      isVisible:true,
    },
    {
      token: '',
      value: 0,
      name: 'QRE',
      imgName: 'QRE',
      isVisible:true,
    },
    {
      token: '',
      value: 0,
      name: 'NTC',
      imgName: 'NTC',
      isVisible:true,
    },
    {
      token: '',
      value: 0,
      name: 'EQN',
      imgName: 'EQN',
      isVisible:true,
    },
  ];
  isDeposit = true;
  provider: Web3Provider;
  nameWallet = "";
  forceId = "";
  isCopy = false;
  isCopyReserned = false;
  isCopyMain = false;
  isLoading = false;
  nameEditReplenish = '';
  isLoadingSendToWallet = false;
  isLoadingReplenish = false;
  addressWallet = "";
  addressWalletClipped = "";
  mainAddress = "";
  isAddBaks = false;
  walletConnect: walletInfo;
  amountTransaction = null;
  amountTransactionTotal = 0;
  chainId = 0;
  userId = null;

  private _unsubscribeAll = new Subject();

  visibleAddress = true;

  constructor(
    // private web3ModalService: Web3ModalService,
    private walletService: WalletService,
    public translate: TranslateService,
    private coreTranslationService: CoreTranslationService,
    private helpInfo: HelpInfo,
    private cdr: ChangeDetectorRef,
    public breakpointObserver: BreakpointObserver,
    private clipboard: Clipboard,
    private _router: Router,
    private web3ModalService: MyWeb3ModalService
  ) {
    this.coreTranslationService.translate(russian, english, chine, hindi);

    this.helpInfo.tokenAddress
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((x) => {
        if (x != null) {
          this.paymentBalance[0].token = x.mfs;
          this.paymentBalance[1].token = x.stablecoin;
          this.paymentBalance[2].token = x.energy;
          this.paymentBalance[3].token = this.getGameToken(this.paymentBalance[3].name);
          this.paymentBalance[4].token = this.getGameToken(this.paymentBalance[4].name);
          this.paymentBalance[5].token = this.getGameToken(this.paymentBalance[5].name);
          
        }
      });
  }

  ngOnInit(): void {
    this.helpInfo.userId
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((x) => {
        console.log("navbar-wallet::ngOnInit userId", x);
        this.userId = x;

        if (this.userId) {
          this.walletService.getUserInfo(this.userId).then((x) => {
            this.mainAddress = x.address;
            this.walletService.getMyInfo(x.address).subscribe((x) => {
              if (x.status && x.status === 404) return;
              this.forceId = x.ForceID;
            });
            this.cdr.detectChanges();
          });
          this.getBalance();
        }
      });
    this.helpInfo.walletAddress
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(async (x) => {
        this.mainAddress = x;
        if (x != null && x.length > 0) {
          this.addressWalletClipped =
            x.slice(0, 7) + "..." + x.slice(x.length - 9);
          this.addressWallet = x;
          this.cdr.detectChanges();
          if (!this.userId) {
            this.userId = await this.walletService.getReservedId(x);
            this.helpInfo.userId.next(+this.userId);
            const resAddress = await this.walletService.getReservedAddress(
              +this.userId
            );
            if (resAddress !== "0x0000000000000000000000000000000000000000") {
              this.reservedAddress = resAddress.toLowerCase();
              this.isSpare = this.reservedAddress === x.toLowerCase();
            }
          }
          await this.getBalance();
          // if(!this.userId) {
          //   this.walletService.getMyInfo(x).subscribe( x=> {
          //     if(x.status && x.status === 404 )
          //     return
          //     this.forceId = x.ForceID;
          //   })
          // } else{
          // this.walletService.getUserInfo(this.userId).then( x=> {
          //   this.mainAddress = x.address;
          //   this.walletService.getMyInfo(x.address).subscribe( x=> {
          //
          //     if(x.status && x.status === 404 )
          //     return
          //     this.forceId = x.ForceID;
          //   })
          //   this.cdr.detectChanges()
          // });
          // }
        } else {
          this.cdr.detectChanges();
        }
      });
    // this.helpInfo.walletInfo.pipe(takeUntil(this._unsubscribeAll)).subscribe(x => {
    //   if(x != null) {
    //     let chainId = this.helpInfo.walletInfo.value.chainId
    //     this.getBalance()
    //   }
    // })
  }

  getGameToken(name: string):string{
    if( name === 'EQN'){
      return  EQN_ADDRESSES[localStorage.getItem('chainId')]
    }
    if( name === 'RES'){
      return  ENERGY_ADDRESSES[localStorage.getItem('chainId')]
    }
    if( name === 'QRE'){
      return  QRE_ADDRESSES[localStorage.getItem('chainId')]
    }
    if( name === 'NTC'){
      return  QEN_ADDRESSES[localStorage.getItem('chainId')]
    }
    return ''
  }

  getBalance() {
    this.nameEditReplenish = '';
    this.walletService.getBalance().then((x) => {
      
      this.walletBalance[0].value = +x.mfs;
      this.walletBalance[1].value = +x.stablecoin;
      this.walletBalance[2].value = +x.energy;
      this.walletBalance[3].value = +x.qre;
      this.walletBalance[4].value = +x.qen;
      this.walletBalance[5].value = +x.eqn;
      this.paymentBalance[2].isVisible = +x.energy > 0;
      this.paymentBalance[3].isVisible = +x.qre > 0;
      this.paymentBalance[4].isVisible = +x.qen > 0;
      this.paymentBalance[5].isVisible = +x.eqn > 0;
      this.cdr.detectChanges();
    });

    console.log("getBalance", this.helpInfo.userId.value);
    if (this.helpInfo.userId.value) {
      this.walletService
        .getBalancesOnPayment(this.helpInfo.userId.value)
        .then((x) => {
          
          this.paymentBalance[0].value = +x.mfs;
          this.paymentBalance[1].value = +x.stablecoin;
          this.paymentBalance[2].value = +x.energy;
          this.paymentBalance[3].value = +x.qre;
          this.paymentBalance[4].value = +x.qen;
          this.paymentBalance[5].value = +x.eqn;
          this.cdr.detectChanges();
        });
    } else {
      this.walletService.getBalancesOnPaymentWithOutId().then((x) => {
        
        this.paymentBalance[0].value = +x.mfs;
        this.paymentBalance[1].value = +x.stablecoin;
        this.paymentBalance[2].value = +x.energy;
        this.paymentBalance[3].value = +x.qre;
        this.paymentBalance[4].value = +x.qen;
        this.paymentBalance[5].value = +x.eqn;
        this.cdr.detectChanges();
      });
    }
  }

  disconnectWallet() {
    this.walletService.isDisconnect.next(true);
    this.walletService.disconect();
    this.web3ModalService.disconect();
    this.helpInfo.isConnect.next(false);
    this.cdr.detectChanges();
  }

  editReplenish(isDeposit: boolean, nameEdit:string) {
    this.amountTransaction = 0;
    this.isDeposit = isDeposit;
    this.nameEditReplenish = nameEdit;
  }

  replenish(token) {
    
    if (this.amountTransaction > 0) {
      this.isLoadingReplenish = true;
      this.walletService.addToPayment(token, this.amountTransactionTotal).then(
        (x) => {
          this.amountTransactionTotal = 0;
          this.amountTransaction = 0;
          this.isLoadingReplenish = false;
          this.nameEditReplenish = '';
          this.getBalance();
        },
        (err) => {
          this.nameEditReplenish = '';
          this.isLoadingReplenish = false;
          this.cdr.detectChanges();
        }
      );
    }
  }

  getDepositeBalance(name: string){
    let result = this.walletBalance.find( x=> x.name.toLowerCase() === name.toLowerCase())
      return result.value;
  }

  changeTotal(value) {
    let balance = this.nameEditReplenish === this.paymentBalance[0].name?this.paymentBalance[0].value: this.paymentBalance[1].value;
    if (this.isDeposit) 
      balance = this.getDepositeBalance(this.nameEditReplenish)
    if (value > balance || value < 0) {
      this.amountTransaction = 0;
      this.cdr.detectChanges();
      this.amountTransaction = this.amountTransactionTotal;
      this.cdr.detectChanges();
      return;
    }
    this.amountTransaction = value;
    this.amountTransactionTotal = value;
    this.cdr.detectChanges();
  }

  actionChangeWithWallet(token) {
    if (this.isDeposit) {
      this.replenish(token);
    } else {
      this.sendToWallet(token);
    }
  }

  sendToWallet(token) {
    // this.isLoadingSendToWallet = true;
    if (this.amountTransaction > 0) {
      this.isLoadingReplenish = true;
      this.walletService.claim(token, this.amountTransactionTotal + "").then(
        (x) => {
          this.isLoadingSendToWallet = false;
          this.isLoadingReplenish = false;
          this.nameEditReplenish = '';
          this.getBalance();
        },
        (err) => {
          this.isLoadingSendToWallet = false;
          this.isLoadingReplenish = false;
          this.nameEditReplenish = '';
          this.cdr.detectChanges();
        }
      );
    }
  }

  copyWallet(type: string, address) {
    this[type] = true;
    this.cdr.detectChanges();
    this.clipboard.copy(address);
    setTimeout(() => {
      this[type] = false;
      this.cdr.detectChanges();
    }, 500);
  }

  ngOnDestroy(): void {
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
    this.helpInfo.walletAddress.next("");
  }
}

<img
  [width]="width"
  [height]="height"
  *ngIf="imgName === 'energy'"
  src="/assets/coin/energy.png"
  alt="" />
<img
  [width]="width"
  [height]="height"
  *ngIf="imgName === 'dai'"
  src="/assets/coin/dai.png"
  alt="" />
<img
  [width]="width"
  [height]="height"
  *ngIf="imgName === 'forcecoin'"
  src="/assets/coin/H.svg"
  alt="" />

<img
  [width]="width"
  [height]="height"
  *ngIf="imgName === 'sfcr'"
  src="/assets/coin/sfcr.png"
  alt="" />
<img
  [width]="width"
  [height]="height"
  *ngIf="imgName === 'sfcr2'"
  src="/assets/coin/sfcr2.png"
  alt="" />
<img
  [width]="width"
  [height]="height"
  *ngIf="imgName === 'sfc2'"
  src="/assets/coin/sfc2.png"
  alt="" />
<img
  [width]="width"
  [height]="height"
  *ngIf="imgName === 'sfcnew'"
  src="/assets/coin/sfcNew.png"
  alt="" />

<img
  [width]="width"
  [height]="height"
  *ngIf="imgName === 'sfcold'"
  src="/assets/coin/sfcOld.png"
  alt="" />
<img
  [width]="width"
  [height]="height"
  *ngIf="imgName === 'matic'"
  src="/assets/coin/matic.png"
  alt="" />
<img
  [width]="width"
  [height]="height"
  *ngIf="imgName === 'rt'"
  src="/assets/coin/rt.png"
  alt="" />
  <img
  [width]="width"
  [height]="height"
  *ngIf="imgName === 'NTC'"
  src="/assets/coin/QEN.svg"
  alt="" />
  <img
  [width]="width"
  [height]="height"
  *ngIf="imgName === 'QRE'"
  src="/assets/coin/QRE.svg"
  alt="" />
  <img
  [width]="width"
  [height]="height"
  *ngIf="imgName === 'EQN'"
  src="/assets/coin/EQN.svg"
  alt="" />
<div
  class="row justify-content-center discription text-center pb-2"
  innerHTML="{{ 'WALLET.TITLE_DESCRIPTION' | translate }}"
></div>
<div
  *ngIf="userId != null && !isSpare"
  class="row justify-content-center discription-warning text-center pb-2"
  innerHTML="{{ 'WALLET.TITLE_WARNING' | translate }}"
></div>

<div class="menu row">
  <div class="menu-header margin-bottom-4 col-12 p-0 center-layout">
    <div
      class="header padding-24px row m-0"
      [ngClass]="reservedAddress ? '' : 'header-mini'"
    >
      <div class="col-12 p-0 text-titel">
        {{ "WALLET.BALANCE" | translate }}
      </div>
      <div class="col-12 p-0">
        <div *ngIf="reservedAddress" class="info-address-header row m-0">
          <div class="col-12 p-0">
            {{ "WALLET.MAIN_WALLET_ADDRESS" | translate }}
          </div>
          <div class="col-12 p-0 address-text">
            {{
              mainAddress.slice(0, 7) +
                "..." +
                mainAddress.slice(mainAddress.length - 9)
            }}
            <span
              class="text-white text-copied mr-1 badge badge-secondary"
              *ngIf="isCopyMain"
              >copied !</span
            >
            <img
              class="cursor-pointer"
              (click)="copyWallet('isCopyMain', mainAddress)"
              src="assets/icon/copy.png"
              alt="copy"
            />
          </div>
        </div>
        <div *ngIf="reservedAddress" class="info-address-header row m-0">
          <div class="col-12 p-0">
            {{ "WALLET.TRUSTED_WALLET_ADDRESS" | translate }}
          </div>
          <div class="col-12 p-0 address-text">
            {{
              reservedAddress.slice(0, 7) +
                "..." +
                reservedAddress.slice(reservedAddress.length - 9)
            }}
            <span
              class="text-white text-copied mr-1 badge badge-secondary"
              *ngIf="isCopyReserned"
              >copied !</span
            >
            <img
              class="cursor-pointer"
              (click)="copyWallet('isCopyReserned', reservedAddress)"
              src="assets/icon/copy.png"
              alt="copy"
            />
          </div>
        </div>
      </div>
    </div>

    <div class="scrollable-container media-list">
      <ng-container *ngFor="let coin of paymentBalance">
       
      <div
        class="row m-0 coin-name-value"
        
        *ngIf="coin.isVisible || coin.name === 'DAI' || coin.name === 'HC'"
        [ngClass]="coin.name === 'DAI' && 'coin-DAI-padding'"
      >
        <div class="col-6">
          <app-crypto-tokens
          [width]="32"
          [height]="32"
          [imgName]=" coin.imgName">
        </app-crypto-tokens>
          
           
          {{ coin.name }}
        </div>
        <div class="col-6 text-right">
          {{ (+coin.value).toFixed(2) }}
        </div>
        <div class="col-12">
          <div
            class="row margin-top-16"
            *ngIf=" nameEditReplenish === '' || nameEditReplenish !== coin.name"
          >
            <div class="col-6">
              <button
                [disabled]="userId != null && !isSpare"
                class="btn btn-grey-with-icon w-100"
                (click)="editReplenish(true,coin.name)"
              >
                <i class="feather icon-arrow-up"></i>

                {{ "WALLET.REPLENISH" | translate }}
              </button>
            </div>
            <div class="col-6 text-right">
              <button *ngIf="coin.name === 'DAI' || coin.name === 'HC'"
                [disabled]="userId != null && !isSpare"
                class="btn btn-grey-with-icon w-100"
                (click)="editReplenish(false,coin.name)"
              >
                <span
                  *ngIf="isLoadingSendToWallet"
                  class="spinner-border spinner-border-sm"
                  role="status"
                  aria-hidden="true"
                ></span>
                <i class="feather icon-arrow-down"></i>

                {{ "WALLET.SEND_TO_WALLET" | translate }}
              </button>
            </div>
          </div>
          <div
            class="row margin-top-16"
            *ngIf=" nameEditReplenish === coin.name"
          >
            <div class="col-12">
              <div class="input-group input-mfs">
                <span class="input-group-text">
                  <button (click)="changeTotal(amountTransaction - 10)">
                    -
                  </button>
                </span>
                <input
                  type="text"
                  class="form-control"
                  [ngModel]="amountTransaction"
                  placeholder="Amount"
                  aria-label="Amount"
                  (ngModelChange)="changeTotal(+$event)"
                />
                <span class="input-group-text">
                  <button (click)="changeTotal(amountTransaction + 10)">
                    +
                  </button>
                </span>
              </div>
            </div>
            <div class="col-6">
              <a
                class="btn btn-grey-with-icon disconet-button"
                (click)="editReplenish(false,'')"
              >
                <i class="feather icon-x"></i>

                {{ "WALLET.CANCEL" | translate }}
              </a>
            </div>
            <div class="col-6 text-right pl-0">
              <a
                class="btn btn-yellow-with-icon disconet-button"
                (click)="actionChangeWithWallet(coin.token)"
              >
                <span
                  *ngIf="isLoadingReplenish"
                  class="spinner-border spinner-border-sm"
                  role="status"
                  aria-hidden="true"
                ></span>
                <span *ngIf="!isDeposit">
                  <i class="feather icon-arrow-down"></i>

                  {{ "WALLET.SEND_TO_WALLET" | translate }}
                </span>
                <span *ngIf="isDeposit">
                  <i class="feather icon-arrow-up"></i>

                  {{ "WALLET.REPLENISH" | translate }}
                </span>
              </a>
            </div>
          </div>
        </div>
      </div>
      </ng-container>
    </div>
  </div>

  <div class="menu-header margin-top-4 col-12 p-0">
    <div class="header wallet-block padding-24px">
      <div class="row">
        <div class="col-6 text-titel">
          <span
            *ngIf="
              reservedAddress &&
                addressWallet.toLowerCase() === reservedAddress.toLowerCase();
              else walletAddress
            "
            >{{ "WALLET.EXTRA_WALLET" | translate }}</span
          >
          <ng-template #walletAddress>{{
            "WALLET.WALLET" | translate
          }}</ng-template>
          <!--          <span #walletAddress>{{ 'WALLET.WALLET' | translate }}</span>-->
        </div>
        <div class="col-6">
          <a
            class="btn btn-grey-with-icon disconet-button"
            (click)="disconnectWallet()"
          >
            {{ "WALLET.DISCONECT" | translate }}
          </a>
        </div>
      </div>
      <div class="row info-address-header">
        <div class="col-12 address-text">
          {{ addressWalletClipped }}
          <span
            class="text-white text-copied mr-1 badge badge-secondary"
            *ngIf="isCopy"
            >copied !</span
          >
          <img
            class="cursor-pointer"
            (click)="copyWallet('isCopy', addressWallet)"
            src="assets/icon/copy.png"
          />
        </div>
      </div>
    </div>

    <div>
      <ng-container *ngFor="let coin of walletBalance">
      <div class="row coin-name-value m-0"  *ngIf="coin.value > 0 || coin.name === 'DAI' || coin.name === 'HC'">
        <div class="col-6">
          <app-crypto-tokens
          [width]="32"
          [height]="32"
          [imgName]="coin.imgName">
        </app-crypto-tokens>
          {{ coin.name }}
        </div>
        <div class="col-6 text-right">
          {{ (+coin.value).toFixed(2) }}
        </div>
      </div>
    </ng-container>
    </div>
  </div>
</div>
<div
  class="row justify-content-center discription text-center pt-2"
  innerHTML="{{ 'WALLET.WARNING' | translate }}"
></div>
